import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from '@app/shared/services/shared.service';
import { StoreService } from '@app/shared/services/store.service';
import { ToasterService } from '@app/shared/services/toaster.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss'],
})
export class OtpVerificationComponent implements OnInit {
  mobile: any;
  resetForm: FormGroup;
  timer: number = 30;
  timerInterval;
  errorData = {
    display: true,
    message: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private storeService: StoreService,
    private sharedService: SharedService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.mobile = this.storeService.get('mobile');
    if (!this.mobile) {
      this.router.navigate(['/forgot-password']);
    }
    this.initForms();
    this.startTimer();
  }

  initForms() {
    this.resetForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
  }

  startTimer() {
    this.timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  onFormSubmit() {
    let data = {
      ...this.resetForm.value,
      mobile: this.mobile,
      type: 'RP',
      userType: 'USER',
    };

    if (this.resetForm.invalid) {
      return;
    }

    this.sharedService.verifyOTP(this.mobile, data).subscribe(
      (response) => {
        if (response.status_code == 1000) {
          this.storeService.set('otpResourceId', response.data);
          this.router.navigate(['/recover/password', response.data]);
        }
      },
      (error) => {
        if (error == 'Bad Request Exception') {
          return this.toaster.open(
            'The entered OTP is incorrect. Please try agian.'
          );
        }

        console.log(error);
        this.toaster.open('Uh Oh! Something went wrong. Please try again.');
      }
    );
  }

  onResendCode() {
    this.timer = 10;
    this.startTimer();
  }
}
