<div class="main-div bg">
  <div>
    <div class="login-logo">
      <img src="assets/bg-images/evnnovator-logo-4.webp" style="width: 25rem" />
    </div>
    <mat-card class="my-card">
      <form class="my-form" [formGroup]="resetForm" (ngSubmit)="onFormSubmit()">
        <mat-card-content>
          <h4>Forgot password</h4>
          <mat-form-field appearance="outline">
            <mat-label>Mobile</mat-label>
            <input
              matInput
              formControlName="mobile"
              minlength="10"
              maxlength="10"
              type="text"
              onkeypress="return /[0-9]/i.test(event.key)"
              required
            />
          </mat-form-field>
          <p class="forgot-text-p">
            Enter your mobile number for verification process, <br />
            we will send 4 digit OTP to your mobile
          </p>
          <div style="color: red">{{ errorData.message }}</div>
        </mat-card-content>
        <mat-card-actions>
          <button
            [disabled]="noWhitespaceValidator()"
            mat-raised-button
            type="submit"
            color="primary"
          >
            Submit
          </button>

          <div class="actions-box">
            <p class="forgot-text">
              Remember password?
              <a routerLink="/login" class="a-link">Sign in</a>
            </p>
            <p class="set-password-text">
              <a routerLink="/login" class="a-link">&larr;Back</a>
            </p>
          </div>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>
