<div class="main-div bg">
  <div>
    <div class="login-logo">
      <img src="assets/bg-images/evnnovator-logo-4.webp" style="width: 25rem" />
    </div>
    <mat-card class="my-card">
      <form class="my-form" [formGroup]="resetForm" (ngSubmit)="onFormSubmit()">
        <mat-card-content>
          <h4>OTP Verification</h4>
          <p class="forgot-text-p">
            Enter OTP code sent to <b>{{ mobile }}</b>
            <a
              routerLink="/forgot-password"
              style="margin-left: 0.2rem"
              class="a-link"
              >Edit?</a
            >
          </p>
          <mat-form-field appearance="outline">
            <mat-label>OTP</mat-label>
            <input
              matInput
              formControlName="otp"
              minlength="4"
              maxlength="4"
              type="text"
              onkeypress="return /[0-9]/i.test(event.key)"
              required
            />
          </mat-form-field>
          <p class="forgot-text-p">
            Didn't receive OTP code?
            <span *ngIf="timer; else ResendButton"
              >RESEND in {{ timer }} seconds</span
            >
            <ng-template #ResendButton>
              <a class="a-link" *ngIf="timer == 0" (click)="onResendCode()"
                >RESEND</a
              >
            </ng-template>
          </p>
          <div style="color: red">{{ errorData.message }}</div>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button type="submit" color="primary">
            Verify
          </button>
          <p class="forgot-text">
            Remember password? <a routerLink="/login" class="a-link">Sign in</a>
          </p>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>
